import Vue from 'vue'
import App from './App.vue'
import router from './router'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import axios from 'axios'
import store from './store'
import Distpicker from 'v-distpicker'


// 引入按钮权限js1
import utilsButtons from './utils/hasAuthorityButtons'


Vue.config.productionTip = false
Vue.use(ElementUI);
Vue.component('v-distpicker', Distpicker)
Vue.prototype.$hasAuthorityButtons = utilsButtons.hasAuthorityButtons
Vue.prototype.$http = axios
if (process.env.NODE_ENV == 'development') {
  Vue.prototype.$url = 'http://127.0.0.1:7001'
} else {
  Vue.prototype.$url = 'https://api.admin.codes-flying.com'
}

const httpRequest = options => { //options 传递过来的参数 {method: '', data: {} || params: {}, url: ''}
  return new Promise((resolve, reject) => {
    let cfg = {
      url: options.url,
      method: options.method,
      headers: {
        'token': sessionStorage.getItem('token') != null ? sessionStorage.getItem('token') : ''
      }
    };
    if (options.method === 'get' || options.method === 'GET') { //判断请求方式
      cfg.params = options.params;
    } else {
      cfg.data = options.data;
    }
    const res = axios(cfg);
    try {
      resolve(res)
    } catch (e) {
      console.log(e)
      reject(e)
    }
  })
};

Vue.prototype.$http = async function request(cfg) {
  let data = await httpRequest(cfg),
      token = sessionStorage.getItem('token'),
      mountButtons = JSON.parse(localStorage.getItem('mountButtons'))
console.log(cfg)

  if (token && !mountButtons && cfg.url.indexOf('/api/login') < 0) {
    this.$message.error('您暂无此权限')
    router.push('/login')
    return
  }
  if (data.data.code === 401) {

    router.push('/login')
    this.$message.error(res.msg)
  } else if (data.data.code === 402) { //无权限

    router.push('/login')
    this.$message.error(res.msg)
    return
  } else if (data.data.code === 403) {
    this.$message.error("暂无权限")
  }
  else {

    return data
  }
}

router.beforeEach((to,from,next) => {
  document.title = 'Codes Flying Digital Game 管理中心'
 next()
})
new Vue({
  router,
  store,
  render: function (h) { return h(App) }
}).$mount('#app')
