/*
 * 权限按钮控制
 * key： 每个按钮的特殊数字
 */
const hasAuthorityButtons = (key) => { 
    let buttons = localStorage.getItem('mountButtons')
    if (!buttons) {
        return false // false为隐藏按钮
    } else {
        buttons = JSON.parse(buttons || '[]')
        let authorityFlag = true // 该值改成false, 即可恢复所有按钮
        if (authorityFlag) {
            return buttons.indexOf(key) > -1 ? true : false
        } else {
            return true
        }
    }
}

export default {
    hasAuthorityButtons
}