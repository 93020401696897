<template>
  <div class="home">
    <div class="header">
      <div class="header_box">
        <div class="header_box_left">
          <img class="logoimg" src="../assets/logo.png" />
        </div>
        <div class="header_box_right">
          <!-- 未登录状态 -->
          <router-link to="/login" class="login_text" tag="div" v-if="!$store.state.token">
           欢迎您，<span class="login">{{ $store.state.username }}</span>
          </router-link>
          <!-- 登录状态 -->
          <div class="login_text" v-if="$store.state.token">
            欢迎您，<span >{{ $store.state.username }}</span><span  class="login"  @click="jumpage('/systemSettings/roleManagement')" style="cursor: pointer;margin:0 10px">系统设置>></span
            ><span class="login login_out" @click="loginOut">退出</span>
          </div>
        </div>
      </div>
    </div>
    <div class="homeContent">
      <div class="buyer_home_box">
        <div class="buyer_menu_box">
          <!-- 用户信息 -->
          <div class="userInfor">
            <div class="user_infor">
              <img :src="$url + $store.state.headerUrl"/>
              <p>欢迎您,{{ $store.state.username }}</p>
            </div>
          </div>
          <div class="menu_box">
            <el-menu
              :default-active="$route.path"
              class="el-menu-vertical-demo"
              :unique-opened="true"
              @open="handleOpen"
              @close="handleClose"
              background-color="#f5f5f6"
              text-color="#000"
              active-text-color="#4d89f7"
            >
              <el-menu-item index="/homePage" @click="jumpage('/homePage')" >
                <span slot="title">首页</span>
              </el-menu-item>
              <el-submenu index="2" v-if="$hasAuthorityButtons(10002)">
                <template slot="title">
                  <span>采购管理</span>
                </template>
                <el-menu-item-group>
                  <el-menu-item
                    index="/procurementManagement/purchaserManagement"
                    @click="
                      jumpage('/procurementManagement/purchaserManagement')
                    "
                    v-if="$hasAuthorityButtons(100021) ">采购商管理</el-menu-item
                  >
                  <el-menu-item
                    index="/procurementManagement/orderManagement"
                    @click="jumpage('/procurementManagement/orderManagement')"
                     v-if="$hasAuthorityButtons(100022) && $hasAuthorityButtons(1000221)">订单管理</el-menu-item
                  >
                </el-menu-item-group>
              </el-submenu>
              <el-submenu index="3"  v-if="$hasAuthorityButtons(10003)">
                <template slot="title">
                  <span>仓储管理</span>
                </template>
                <el-menu-item-group>
                  <el-menu-item
                    index="/warehouseManagement/inventoryPageManagement"
                    @click="
                      jumpage('/warehouseManagement/inventoryPageManagement')
                    "
                    v-if="$hasAuthorityButtons(100031) && $hasAuthorityButtons(1000311)">库存管理</el-menu-item
                  >
                  <el-menu-item
                    index="/warehouseManagement/specialofferManagement"
                    @click="
                      jumpage('/warehouseManagement/specialofferManagement')
                    "
                   v-if="$hasAuthorityButtons(100031)" >特价管理</el-menu-item
                  >
                </el-menu-item-group>
              </el-submenu>
              <el-submenu index="4" v-if="$hasAuthorityButtons(10004)">
                <template slot="title">
                  <span>财务管理</span>
                </template>
                <el-menu-item-group>
                  <el-menu-item
                    index="/financialManagement/storedValueManagement"
                    @click="
                      jumpage('/financialManagement/storedValueManagement')
                    "
                    v-if="$hasAuthorityButtons(100041) && $hasAuthorityButtons(1000411)">储值管理</el-menu-item
                  >
                  <el-menu-item
                    index="/financialManagement/financialData"
                    @click="jumpage('/financialManagement/financialData')"
                    >财务数据</el-menu-item
                  >
                </el-menu-item-group>
              </el-submenu>
              <el-submenu index="5" v-if="$hasAuthorityButtons(10005)">
                <template slot="title">
                  <span>三方管理</span>
                </template>
                <el-menu-item-group>
                  <el-menu-item
                    index="/tripartiteManagement/platformConfiguration"
                    @click="jumpage('/tripartiteManagement/platformConfiguration')"
                   v-if="$hasAuthorityButtons(100051)">平台配置</el-menu-item
                  >
                  <el-menu-item
                    index="/tripartiteManagement/orderManagement"
                    @click="jumpage('/tripartiteManagement/orderManagement')"
                    v-if="$hasAuthorityButtons(100052)">订单管理</el-menu-item
                  >
                </el-menu-item-group>
              </el-submenu>
              <el-submenu index="6"  v-if="$hasAuthorityButtons(10006)">
                <template slot="title">
                  <span>系统设置</span>
                </template>
                <el-menu-item-group>
                  <el-menu-item
                    index="/systemSettings/operationlog"
                    @click="jumpage('/systemSettings/operationlog')"
                    v-if="$hasAuthorityButtons(100061)">操作日志</el-menu-item
                  >
                  <el-menu-item
                    index="/systemSettings/keyHistory"
                    @click="jumpage('/systemSettings/keyHistory')"
                    v-if="$hasAuthorityButtons(100063) ">KEY历史</el-menu-item
                  >
                  <el-menu-item
                    index="/systemSettings/protocolSettings"
                    @click="jumpage('/systemSettings/protocolSettings')"
                     v-if="$hasAuthorityButtons(100064) && $hasAuthorityButtons(1000641)">协议设置</el-menu-item
                  >
                  <el-menu-item
                    index="/systemSettings/gameSettings"
                    @click="jumpage('/systemSettings/gameSettings')"
                    v-if="$hasAuthorityButtons(100065) && $hasAuthorityButtons(1000651)">游戏设置</el-menu-item
                  >
                  <el-menu-item
                    index="/systemSettings/otherSettings"
                    @click="jumpage('/systemSettings/otherSettings')"
                    v-if="$hasAuthorityButtons(100066) && $hasAuthorityButtons(1000661)">其他设置</el-menu-item
                  >
                  <el-menu-item
                    index="/systemSettings/companySetting"
                    @click="jumpage('/systemSettings/companySetting')"
                    >开票设置</el-menu-item
                  >
                  <el-menu-item
                    index="/systemSettings/carouselSettings"
                    @click="jumpage('/systemSettings/carouselSettings')"
                      v-if="$hasAuthorityButtons(100067) && $hasAuthorityButtons(1000671)">轮播图设置</el-menu-item
                  >
                  <el-menu-item
                    index="/systemSettings/roleManagement"
                    @click="jumpage('/systemSettings/roleManagement')"
                     v-if="$hasAuthorityButtons(100068) && $hasAuthorityButtons(1000681)">角色管理</el-menu-item
                  >
               
                   <el-menu-item
                    index="/systemSettings/administratorSettings"
                    @click="jumpage('/systemSettings/administratorSettings')"
                      v-if="$hasAuthorityButtons(100062) && $hasAuthorityButtons(1000621)">管理员设置</el-menu-item
                  >
                </el-menu-item-group>
              </el-submenu>
            </el-menu>
          </div>
        </div>
        <div class="buyer_content_box">
          <router-view />
        </div>
      </div>
    </div>
    <div class="footBox" v-if="$route.path == '/homePage'">
      <div>技术支持：斯瓦格（厦门）网络科技有限公司</div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      isShow: true,
    };
  },
  computed: {

  },
  watch: {
    $route(value) {
      if (value.path == "/homePage") {
        this.isShow = true;
      } else {
        this.isShow = false;
      }
    },
  
  },
  created() {
    this.getUserDetail()
  },
  methods: {
    jumpage(e) {
      this.$router.push(e);
    },
    handleOpen(key, keyPath) {
      // console.log(key, keyPath);
    },
    handleClose(key, keyPath) {
      // console.log(key, keyPath);
    },
    //获取管理员信息
     async getUserDetail() {
      let cfg = {
        method: "GET",
        url: this.$url + "/api/admin/getAdminDetail",
        params: {},
      };
      let res = await this.$http(cfg);
      if (res.data.code === 0) {
        sessionStorage.setItem('stockNum',res.data.data.stockNum)
        sessionStorage.setItem('headerUrl',res.data.data.avatar)
 
       this.$store.commit('setHeaderUrl',res.data.data.avatar)
        this.$store.commit("setStockNum", res.data.data.stockNum);
      } else {
        this.$message.error(res.data.msg);
      }
    },
    //退出登录
    async loginOut() {
      let cfg = {
        method: "POST",
        url: this.$url + "/api/logout",
        data: {},
      };
      let res = await this.$http(cfg);
      if (res.data.code === 0) {
        this.$message.error("退出成功");
        sessionStorage.removeItem("token");
        sessionStorage.removeItem("admin");
        sessionStorage.removeItem("username");
        sessionStorage.removeItem("headerUrl");
        sessionStorage.removeItem("stockNum");
        sessionStorage.removeItem("accountBalance");
        this.$router.push("/login");
      } else {
        this.$message.error(res.data.msg);
      }
    },
  },
};
</script>
<style scoped lang="less">
.home {
  .buyer_home_box {
    position: relative;
    display: flex;
    justify-content: space-between;
    width: 90%;
    
    margin: 20px auto;
    .buyer_menu_box {
      position: fixed;
      width: 150px;
      margin-right: 20px;
      // height: calc(100vh - 120px);
      .userInfor {
        display: flex;
        align-items: center;
        justify-content: center;
        background: #fff;
        border-radius: 10px;
        width: 150px;
        height: 120px;
        .user_infor {
          text-align: center;
          p {
            font-size: 12px;
            font-family: PingFang;
            font-weight: bold;
            color: #333333;
            line-height: 30px;
            margin-bottom: 0;
          }
          img {
            width: 50px;
            height: 50px;
            border-radius: 50%;
            object-fit: cover;
          }
        }
      }

      .menu_box {
        margin-top: 10px;
        .el-menu {
          border: 0;
        }
        /deep/.el-menu-item {
          width: 100%;
          text-align: center;
          margin: 0 auto;
          height: 54px;
          line-height: 54px;

          span {
            display: inline-block;

            font-size: 14px;
            font-family: PingFang;
            font-weight: 500;
          }

          .icon {
            width: 25px;
            height: 25px;
            display: inline-block;
            background-repeat: no-repeat;
            margin-right: 3px;
          }

          // &:first-child{}
        }

        /deep/.el-menu-item:nth-child(2) {
          border-top: 0;
          border-top-right-radius: 10px;
          border-top-left-radius: 10px;
          
        }
        .item_menu {
          width: 100%;
          margin: 0 auto;
          border-top: 1px solid #dcdfe6 !important;
        }

        /deep/.el-menu-item:last-child {
          border-bottom-left-radius: 10px;
          border-bottom-right-radius: 10px;
        }
        /deep/.el-menu-item:nth-child(n + 1) {
          background: #fff !important;
        }
        /deep/.el-submenu:nth-child(n) {
          width: 100% !important;
          background: #fff !important;
        }
        /deep/.el-submenu:last-child {
          border-bottom-left-radius: 10px !important;
          border-bottom-right-radius: 10px !important;
          overflow: hidden;
        }
        .el-menu-item:hover {
          background: #fff !important;
        }
        /deep/.el-submenu {
          width: 80%;
          margin: 0 auto;
          .el-menu-item {
            border: 0;
            min-width: 0 !important;
            margin: 0 !important;
            font-size: 12px;
            font-family: PingFang;
            font-weight: 500;
            color: #999999;
          }
          .el-menu-item-group__title {
            padding: 0;
          }
        }
        /deep/.el-submenu__title {
          width: 80%;
          text-align: center;
          margin: 0 auto;
          font-size: 18px;
          height: 54px;
          line-height: 54px;
          background: #fff !important;
          border-top: 1px solid #dcdfe6;
          font-size: 14px;
          font-family: PingFang;
          font-weight: 500;
          color: #333333;
        }

        /deep/.el-submenu__title:hover {
          span {
            color: #4d89f7;
          }
        }
        /deep/.el-submenu__icon-arrow {
          top: 56%;
          font-size: 8px;
        }
      }
    }
    .buyer_content_box {
      position: absolute;
      // bottom: 80px;
      right:0;
      width: calc(100% - 170px);
      // background: #fff;
      display: flex;
    }
  }
  .header {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 70px;
    background: #4d89f7;
    position: fixed;
    z-index:999;
    .header_box {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 90%;
      margin: 0 auto;
      .header_box_left {
        display: flex;
        align-items: center;
        .logoimg {
          height: 60px;
          object-fit: cover;
          margin-right: 10px;
        }
      }
      .header_box_right {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        width: 50%;
        color: #fff;
        .right_box {
          display: flex;
          align-items: center;
          justify-content: space-between;
          width: 50%;
          div {
            font-size: 14px;
            font-family: Adobe Heiti Std;
            font-weight: normal;
            color: #ffffff;
            line-height: 30px;
            box-sizing: border-box;
          }
          .active {
            border-bottom: 2px solid #fff;
          }
        }
        .login_text {
          font-size: 14px;
          font-family: Adobe Heiti Std;
          font-weight: normal;
          color: #ffffff;
          line-height: 30px;
          .login {
            text-decoration: underline;
          }
          .login_out {
            text-decoration: none;
            margin-left: 10px;
            cursor: pointer;
          }
        }
      }
    }
  }
  .homeContent {
    padding-top:80px;
    display: flex;
    min-height: calc(100vh - 70px);
    background: #f5f5f6;
  }
  .home_bottom_box {
    display: flex;
    align-items: center;
    justify-content: center;
    background: #373f5e;
    height: 100px;
    font-size: 10px;
    font-family: PingFang;
    font-weight: 500;
    color: #ffffff;
    line-height: 30px;
    .home_bottom {
      width: 90%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .home_bottom_left {
        width: 40%;
        div {
          line-height: 25px;
        }
        .about_us,
        .link_box {
          display: flex;
          align-items: center;
          justify-content: flex-start;
        }
        .about_us span {
          margin-right: 20px;
        }
        .item_link {
          display: flex;
          align-items: center;
          margin-right: 15px;
          .line {
            width: 1px;
            height: 12px;
            margin-left: 5px;
            background: #fff;
          }
        }
      }
      .home_bottom_right {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        flex-direction: row;
      }
    }
  }
  .footBox {
    position: fixed;
    bottom: 0;
    width:100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    background: #f5f5f6;

    div {
      width: 100%;
      margin: 0 auto 20px;
      padding: 20px 0 20px 40px;
      background: #fff;
      border-radius: 10px;
      text-align: left;
    }
  }
}
</style>
