import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

Vue.use(VueRouter)

const routes = [
  {
    path:'/',   //根路径
    redirect:'/homePage'   //重定向url地址
},
  {
    path: '/',
    name: 'Home',
    component: Home,
    children: [
      {
        path: '/homePage',
        name: 'homePage',
        component: function () {
          return import(/* webpackChunkName: "about" */ '../views/homePage.vue')
        }
      },
      {
        path: '/pdf',
        name: 'pdf',
        component: function () {
          return import(/* webpackChunkName: "about" */ '../views/pdf.vue')
        }
      },
      {
        path: '/procurementManagement/purchaserManagement',
        name: 'purchaserManagement',
        component: function () {
          return import(/* webpackChunkName: "about" */ '../views/procurementManagement/purchaserManagement.vue')
        },

      },
      {
        path: '/procurementManagement/orderManagement',
        name: 'orderManagement',
        component: function () {
          return import(/* webpackChunkName: "about" */ '../views/procurementManagement/orderManagement.vue')
        },

      },
      {
        path: '/procurementManagement/orderDetail',
        name: 'orderDetail',
        component: function () {
          return import(/* webpackChunkName: "about" */ '../views/procurementManagement/orderDetail.vue')
        },

      },
      {
        path: '/tripartiteManagement/orderDetail',
        name: 'orderDetailOther',
        component: function () {
          return import(/* webpackChunkName: "about" */ '../views/tripartiteManagement/orderDetail.vue')
        },

      },
      {
        path: '/warehouseManagement/inventoryPageManagement',
        name: 'inventoryPageManagement',
        component: function () {
          return import(/* webpackChunkName: "about" */ '../views/warehouseManagement/inventoryPageManagement.vue')
        },

      },
      {
        path: '/warehouseManagement/specialofferManagement',
        name: 'specialofferManagement',
        component: function () {
          return import(/* webpackChunkName: "about" */ '../views/warehouseManagement/specialofferManagement.vue')
        },

      },
      {
        path: '/warehouseManagement/gameManagement',
        name: 'gameManagement',
        component: function () {
          return import(/* webpackChunkName: "about" */ '../views/warehouseManagement/gameManagement.vue')
        },

      },
      {
        path: '/financialManagement/financialData',
        name: 'financialData',
        component: function () {
          return import(/* webpackChunkName: "about" */ '../views/financialManagement/financialData.vue')
        },

      },
      {
        path: '/financialManagement/storedValueManagement',
        name: 'storedValueManagement',
        component: function () {
          return import(/* webpackChunkName: "about" */ '../views/financialManagement/storedValueManagement.vue')
        },

      },
      {
        path: '/financialManagement/storedValueHistory',
        name: 'storedValueHistory',
        component: function () {
          return import(/* webpackChunkName: "about" */ '../views/financialManagement/storedValueHistory.vue')
        },

      },
      {
        path: '/tripartiteManagement/orderManagement',
        name: 'orderManagement',
        component: function () {
          return import(/* webpackChunkName: "about" */ '../views/tripartiteManagement/orderManagement.vue')
        },

      },
      {
        path: '/tripartiteManagement/platformConfiguration',
        name: 'platformConfiguration',
        component: function () {
          return import(/* webpackChunkName: "about" */ '../views/tripartiteManagement/platformConfiguration.vue')
        },

      },
      {
        path: '/systemSettings/keyHistory',
        name: 'keyHistory',
        component: function () {
          return import(/* webpackChunkName: "about" */ '../views/systemSettings/keyHistory.vue')
        },

      },
      {
        path: '/systemSettings/companySetting',
        name: 'companySetting',
        component: function () {
          return import(/* webpackChunkName: "about" */ '../views/systemSettings/companySetting.vue')
        },

      },
      {
        path: '/systemSettings/gameSettings',
        name: 'gameSettings',
        component: function () {
          return import(/* webpackChunkName: "about" */ '../views/systemSettings/gameSettings.vue')
        },

      },
      {
        path: '/systemSettings/otherSettings',
        name: 'otherSettings',
        component: function () {
          return import(/* webpackChunkName: "about" */ '../views/systemSettings/otherSettings.vue')
        },

      },
      {
        path: '/systemSettings/protocolSettings',
        name: 'protocolSettings',
        component: function () {
          return import(/* webpackChunkName: "about" */ '../views/systemSettings/protocolSettings.vue')
        },

      },
      {
        path: '/systemSettings/carouselSettings',
        name: 'carouselSettings',
        component: function () {
          return import(/* webpackChunkName: "about" */ '../views/systemSettings/carouselSettings.vue')
        },

      },
      {
        path: '/systemSettings/roleManagement',
        name: 'roleManagement',
        component: function () {
          return import(/* webpackChunkName: "about" */ '../views/systemSettings/roleManagement.vue')
        },

      },
      {
        path: '/systemSettings/new',
        name: 'new',
        component: function () {
          return import(/* webpackChunkName: "about" */ '../views/systemSettings/new.vue')
        },

      },
      {
        path: '/systemSettings/administratorSettings',
        name: 'administratorSettings',
        component: function () {
          return import(/* webpackChunkName: "about" */ '../views/systemSettings/administratorSettings.vue')
        },

      },
      {
        path: '/systemSettings/operationlog',
        name: 'operationlog',
        component: function () {
          return import(/* webpackChunkName: "about" */ '../views/systemSettings/operationlog.vue')
        },

      },

    ]

  },
  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: function () {
      return import(/* webpackChunkName: "about" */ '../views/About.vue')
    }
  },
  {
    path: '/login',
    name: 'login',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: function () {
      return import(/* webpackChunkName: "about" */ '../views/login.vue')
    }
  }
]

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes
})

export default router
